<template>
  <div>
    <header>
      <div class="logo">
        <p class="logoimg"></p>
        <p class="logoname">Oro Crédito</p>
      </div>

      <nav>
        <a href="/">Inicio</a>
        <a href="#">Eliminación de la cuenta</a>
       
      
      </nav>
    </header>
    <section id="contactAbout">
      <div class="text">Eliminación de la cuenta</div>
    </section>

    <section id="formtext">
      <div class="formtexttitle">
          Si decide eliminar su cuenta, le proporcionaremos pasos sencillos. Tenga en cuenta que una vez que se elimine la cuenta, todos los datos asociados se borrarán de forma permanente y no se podrán recuperar. Si está seguro de que desea continuar con la eliminación de la cuenta, siga estos pasos:
      </div>
      <div class="formtextstepnum">
        Ingrese el número de teléfono con el que se registró en esta aplicación.<br>
        1.Ingrese a su cuenta <br>
        2.obtener código de verificación<br>
        3.Elige eliminar datos<br>
        4.Confirmar la eliminación<br>
        5.Realizar operación de eliminación<br>
        6.Retírese con seguridad<br>
      </div>
      <div class="formtexttitle">
        Asegúrese de haber leído atentamente los consejos y advertencias relevantes y de haber confirmado su decisión antes de realizar la operación de eliminación. Si tiene alguna pregunta o necesita ayuda, no dude en ponerse en contacto con nuestro equipo de atención al cliente.
      </div>
      <div class="vcode" id="app" v-if="hide != 1">
        <div class="vtitle">Verifica tu identidad</div>
        <div class="step1" v-if="step == 1">
          <div class="input">
            <div class="inputt">
              Número de cuenta (número de teléfono móvil)
            </div>
            <input
              v-model="phone"
              id="correo"
              type="text"
              class="inputtext"
              placeholder="por favor escribe"
            />
            <div class="tips" v-if="showtip">
              {{ tips }}
            </div>
          </div>
          <div class="btn" v-on:click="nextstep(2)">{{ loading?'loading...':'Siguiente' }}</div>
        </div>
        <div class="step2" v-if="step == 2">
          <div class="input">
            <div class="inputt">número de CURP *</div>
            <input
              v-model="idcard"
              id="correo"
              type="text"
              class="inputtext"
              placeholder="por favor escribe"
            />
          </div>
          <div class="tips" v-if="showtip">
              {{ tips }}
            </div>
          <div class="btn" v-on:click="nextstep(3)">{{ loading?'loading...':'Siguiente' }}</div>
          <div class="btn2" v-on:click="upstep(1)">Anterior</div>
        </div>
        <div class="step3" v-if="step == 3">
          <div class="input">
            <div class="inputt">Código de verificación *</div>
            <div class="inputext">
              Se ha enviado el código de verificación, revíselo cuidadosamente
            </div>
            <div class="mycode">
              <input
                maxlength="6"
                v-model="vcode"
                id="correo"
                type="text"
                class="inputtext"
                placeholder="por favor escribe"
              />
              <div class="time" v-if="countdown > 0">{{ countdown }}S</div>
              <div class="time2" v-else @click="startCountdown">Obtener</div>
            </div>
          </div>
          <div class="btn" v-on:click="submitdata(2)">{{ loading?'loading...':'Siguiente' }}</div>
          <div class="btn2" v-on:click="upstep(2)">Anterior</div>
        </div>
      </div>
    </section>
    <div id="foot">
     
      <div class="contact">
        <p>
          Address:
          <br />Av Eloy Cavazos 7800, Jardines de Andalucía, 67150 Guadalupe, N.L.
        </p>
        <p>
          Email:
          <br />feufuw454@gmail.com
        </p>
      </div>
      <p class="end">© 2024 Oro Crédito. Todos los derechos reservados.</p>
    </div>
  </div>
</template>
<script>
import  axios  from "axios"
export default {
  name: "componentName",
  props: [],
  components: {},
  data() {
    return {
      hide: localStorage.getItem("hide"),
      pack: "mx.orocredito.prest",
      phone: "",
      idcard: "",
      vcode: "",
      step: 1,
      countdown: localStorage.getItem("time") || 0,
      showtip:false,
      tips:'',
      loading:false,
    }
  },
  mounted() {},
  created() {
    if (localStorage.getItem("time") > 0) {
      this.startCountdown()
    }
  },
  methods: {
    startCountdown() {
      // 启动倒计时
      if (localStorage.getItem("time") > 0) {
        this.countdown = localStorage.getItem("time")
      } else {
        this.countdown = 60
      }

      if (this.countdown == 60) {
        this.sendsms()
      }
      // 创建计时器
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          // 倒计时结束，清除计时器
          clearInterval(timer)
        }
        localStorage.setItem("time", this.countdown)
      }, 1000)
    },
    nextstep(step) {
      if (step == 2 && !this.phone) {
        this.showtip=true
        this.tips="por favor escribe Número de cuenta (número de teléfono móvil)"
        return
      }
      if (step == 3 && !this.idcard) {
        this.showtip=true
        this.tips= "por favor escribe número de CURP"
        return
      }
      if (step == 2) {
        this.apiphone(2)
      }
      if (step == 3) {
        this.apiIdcard(2)
      }
    },
    sendsms() {
      axios.post("https://api-mx.apollomxout.com/api/order/view/send-sms", {
          packageName: this.pack,
          phone: this.phone,
          type: "SEND_SMS",
          webViewSendSms: {
            type: "user_cancel_sms_verify",
            uniquCode: "123"
          }
        })
        .then(function(response) {
          if (response.data.code == "00" || response.data.code == "200") {
            alert("Enviado correctamente")
          } else {
            alert(response.data.message)
          }
        })
        .catch()
    },
    apiphone() {
      var that = this
      that.loading = true
      axios.post("https://api-mx.apollomxout.com/api/order/view/exists-id-no", {
          packageName: this.pack,
          phone: this.phone,
          type: "EXISTS_ID_NO"
        })
        .then(function(response) {
          that.loading = false
          if (response.data.code == "00" || response.data.code == "200") {
            if (response.data.data.existsIdNoResult == true) {
              that.step = 2
            } else {
              that.step = 3
            }
          } else {
            
            that.showtip=true
            that.tips=response.data.message
          }
        })
        .catch()
    },
    apiIdcard() {
      var that = this
      that.loading = true
      axios.post(
          "https://api-mx.apollomxout.com/api/order/view/check-phone-idNo",
          {
            packageName: this.pack,
            phone: this.phone,
            idNo: this.idcard,
            type: "CHECK_PHONE_IDNO"
          }
        )
        .then(function(response) {
          that.loading = false
          if (response.data.code == "00" || response.data.code == "200") {
            
            if (response.data.data.existsIdNoResult == true) {
              that.apiCheckOrder()
            } else {
              that.showtip=true
              that.tips="Error en la verificación del número de identificación"
         
            }
          } else {
            that.showtip=true
            that.tips=response.data.message
          }
        })
        .catch()
    },
    apiCheckOrder() {
      var that = this
      that.loading = true
      axios.post(
          "https://api-mx.apollomxout.com/api/order/view/check-progress-order",
          {
            packageName: this.pack,
            phone: this.phone,
            type: "CHECK_PROGRESS_ORDER"
          }
        )
        .then(function(response) {
          that.loading = false
          if (response.data.code == "00" || response.data.code == "200") {
            if (response.data.data.existsIdNoResult == false) {
              that.step = 3
            } else {
              that.showtip=true
              that.tips="Eliminar cuenta fallido Lamentamos mucho informarle que no se puede eliminar su cuenta debido a alguna de las siguientes razones:\n1.Tiene préstamos en proceso.\n2.Tiene préstamos pendientes."
              
            }
          } else {
            that.showtip=true
            that.tips=response.data.message
          }
        })
        .catch()
    },
    submitdata() {
      var that = this
      that.loading = true
      axios.post("https://api-mx.apollomxout.com/api/order/view/user-withdraw", {
          packageName: this.pack,
          phone: this.phone,
          verifyCode: this.vcode,
          type: "USER_WITHDRAW"
        })
        .then(function(response) {
          that.loading = false
          if (response.data.code == "00" || response.data.code == "200") {
            alert("Su cuenta ha sido eliminada con éxito.")
            localStorage.setItem("hide", 1)
            location.reload()
          } else {
            that.showtip=true
            that.tips=response.data.message
          }
        })
        .catch()
    }
  }
}
</script>
<style lang="less">

#contactAbout {
  color: #fff;
    text-align: center;
    height: 30vw;
    width: 100%;
    background: linear-gradient( 270deg, #FF8A55 0%, #FF5000 100%);
    background-size: 100% 100%;
    line-height: 30vw;

  }

  #contactAbout .text {
    font-size: 3vw;
    font-weight: 800;
    
  }

  #contactAbout .mintitle {
    color: #333;
  
  }

  #formtext {
    font-size: 1.1vw;

    width: 100vw;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2vw;
    padding:2vw;
    box-sizing: border-box;
    color: #000;
    text-align: center;
   .formtexttitle{
    font-size: 1.2vw;
    margin-bottom: 2vw;
    margin-top: 4vw;
    text-align: left;
   }
   .formtextstepnum{
   
    color: #999;
    line-height: 1.5;
    text-align: left;

   }
  }

  .inputt {
    margin-bottom: 1vw;
    margin-top: 2vw;
    color: #fff;
  }
  .tips{
    color: red;
    margin: 2vw 0;
    
  }

  .inputtext {
    width: 30vw;
  
    height: 2vw;
    border-radius: 1vw;
    border: 1px solid #d3d3d3;
    padding-left: 2vw;
  }

  .mycode {
    position: relative;
  }

  .time {
    position: absolute;
    right: 0px;
    padding-left: 1vw;

    cursor: pointer;
    color: #999999;
    margin-top: -3vw;
    z-index: 99;
  }

  .time2 {
    position: absolute;
    right: 0px;
    padding-left: 1vw;
    border-left: 1px solid #999;
    cursor: pointer;
    color: #211fa1;
    margin-top: -3vw;
    z-index: 99;
  }

  .inputtext2 {
    padding-top: 1vw;
    width: 100%;
    height: 4vw;
    border-radius: 1vw;
    border: 1px solid #d3d3d3;
    padding-left: 2vw;
  }

  .formbox .input {

    display: inline-block;
  }

  .inputvox {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    background: linear-gradient( 270deg, #FF8A55 0%, #FF5000 100%);
    color: #fff;
    text-align: center;
    width: 10vw;
    height: 3vw;
    border-radius: 1.5vw;
    line-height: 3vw;
    margin: 2vw auto;
    cursor: pointer;
    font-size: 1.2vw;
  }

  .btn2 {
    font-size: 20px;

    background-color: #fff;
    color: #000;
    text-align: center;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    line-height: 50px;
    margin: 20px auto;
    cursor: pointer;
  }

  #formtext .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  #formtext p {
    font-size: 16px;
    color: #797979;
  }

  #formtext .vcode {
    

    margin: 4vw auto;
    background: linear-gradient( 270deg, #FF8A55 0%, #FF5000 100%);
    padding: 5vw 3vw;
    border-radius: 2vw;
  }

  #formtext .vcode .vtitle {
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 2vw;
    margin-bottom: 3vw;
  }

  #formtext .vcode .input {
 
    margin: 0 auto;
  }

  #formtext .inputext {
    color: #fff;
    font-size: 12px;
    margin-bottom: 10px;
   
  }

  #foot {
  
  background: #2f2f2f;
  color: #fff;
  padding: 3vw 0;
  .title {
    color: #fff;
    padding: 2vw 0 0 !important;
  }

  .contact {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1vw;
    color: #ffffff;
    text-align: left;
   
    p {
      font-family: Helvetica;
      font-size: 1vw;
      color: #ffffff;
      text-align: left;
      line-height: 1.4;
    }
  }

  .end {
    margin-top: 2vw;
    width: 100%;
    text-align: center;
    font-family: Helvetica;
    font-size: 1vw;
    color: #ffffff;
   
  }
}
</style>
