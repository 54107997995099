<template>
  <div>
    <header>
      <div class="logo">
        <p class="logoimg"></p>
        <p class="logoname">Oro Crédito</p>
      </div>

      <nav>
        <a href="#home">Inicio</a>
        <a href="#product">producto</a>
        <a href="#how">Cómo funciona</a>
        <a href="#privacy">POLÍTICA DE PRIVACIDAD</a>
        <a @click="jumcuenta">Eliminación de la cuenta</a>
      </nav>
    </header>
    <div class="home" id="home">
      <div class="mytitle">
        Oro Crédito
        <div class="min">
          Préstamo personal confiable Obtenga hasta $10,000 de forma rápida y
          segura.
        </div>
      </div>
      <div class="homeimg">
        <img src="../assets/img/bg1.png" alt="" />
      </div>
      <!-- <a
        target="view_window"
        href="https://play.google.com/store/apps/details?id=mx.orocredito.prest"
        class="googlelogo"
      ></a> -->
    </div>
    <div class="product" id="product">
      <p class="title">Nuestro producto</p>
      <div class="productlist">
        <div class="productitem">
          <img src="../assets/img/a1.png" alt />
          <div class="itemmsg">
            <strong>Solo CURP</strong> <br />
            Alta tasa de aprobación y transferencia rápida
          </div>
        </div>
        <div class="productitem">
          <img src="../assets/img/a2.png" alt />
          <div class="itemmsg">
            <strong>Seguro y confiable</strong> <br />
            Tus datos están 100% seguros.
          </div>
        </div>
        <div class="productitem">
          <img src="../assets/img/a3.png" alt />
          <div class="itemmsg">
            <strong>Responder inmediatamente</strong> <br />
            Resuélvelo inmediatamente y deposita dinero en 5 minutos.
          </div>
        </div>
      </div>
    </div>
    <div class="company" id="how">
      <p class="title">Cómo funciona</p>
      <div class="companylist">
        <div class="companyitem">
          <div class="left">
            <img src="../assets/img/card1.png" alt />
          </div>
          <div class="right">
            <div class="msg">
              <div class="msgitem">
                <img src="../assets/img/01.png" alt="" />Descarga la APP Oro Crédito desde la tienda de aplicaciones
              </div>
              <div class="msgitem">
                <img src="../assets/img/02.png" alt="" />Regístrate e ingresa a Oro Crédito
              </div>
              <div class="msgitem">
                <img src="../assets/img/03.png" alt="" />Complete la información e inicie la solicitud de préstamo.
              </div>
              <div class="msgitem">
                <img src="../assets/img/04.png" alt="" />Procese rápidamente su orden de préstamo en 1 hora
              </div>
              <div class="google"  @click="opengooglepaly">
                <img style="width: 10vw;cursor: pointer;" src="../assets/img/google.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product" id="product">
      <p class="title"> Nuestras características</p>
      <div class="productlist">
        <div class="productitem">
          <img src="../assets/img/lab1.png" alt />
          <div class="itemmsg">
            Monto del préstamo, disponible en cualquier momento
           
          </div>
        </div>
        <div class="productitem">
          <img src="../assets/img/lab2.png" alt />
          <div class="itemmsg">
            
            Respuesta inmediata, depósito en 5 minutos
          </div>
        </div>
        <div class="productitem">
          <img src="../assets/img/lab3.png" alt />
          <div class="itemmsg">
           
            Sus datos personales están cifrados y protegidos
          </div>
        </div>
        <div class="productitem" >
          <img src="../assets/img/lab4.png" alt />
          <div class="itemmsg" >
           
            Presta atención las 24 horas del día, los 365 días del año
          </div>
        </div>
      </div>
    </div>

    <div class="features" id="features">
      <p class="title">Sobre nosotros</p>
      <div class="companyitem">
        <div class="right">
          <p class="msg">
            Somos una plataforma dedicada a brindar a los usuarios servicios de préstamos en línea convenientes, rápidos y seguros. Con tecnología financiera profesional y un concepto de servicio centrado en el usuario, adaptamos soluciones de préstamos flexibles para que los usuarios puedan satisfacer fácilmente sus necesidades financieras en diversos escenarios, como compras, viajes y estudios. Nuestro objetivo es brindar a cada usuario servicios transparentes y justos, haciendo que los préstamos y los préstamos sean menos complicados.
            <br>
Elíjanos para disfrutar de servicios financieros considerados y convenientes que le ayudarán a avanzar hacia un futuro mejor.
          </p>
        </div>
        <div class="left">
          <img src="../assets/img/sobre1.png" alt />
        </div>
      </div>
    </div>
   
    <div class="privacy" id="privacy">
      <p class="title" s>Política de privacidad</p>
      <div :class="!showmore ? 'privacymsg showmore' : 'privacymsg'">


El desarrollador de Oro Crédito (también conocido como "nosotros", "nuestro" o "nuestro") es Fei-Fei Li. Oro Crédito comprende la importancia de la privacidad del usuario y estamos comprometidos a brindar servicios seguros y transparentes a cada usuario, garantizando que la información personal de los usuarios esté siempre protegida al más alto nivel. Esta Política de Privacidad se aplica a los servicios proporcionados por Oro Crédito y establece cómo recopilamos, usamos y divulgamos sus "Datos Personales", "Información de Identificación Personal" u otra información personal relacionada con su acceso y uso de Oro Crédito (colectivamente, " Datos Personales"). Si es nuestro usuario, lea atentamente esta Política de Privacidad para obtener más información sobre cómo utilizamos sus datos personales.
<br>

<div class="t">1. Datos personales que recopilamos</div> 
<strong>Información personal:</strong>  Incluyendo nombre, DNI, dirección, número de teléfono, etc., utilizada para verificar la autenticidad de la información proporcionada por el usuario y para la gestión de riesgos y control de fraude. Solo recopilaremos su información con su consentimiento y no compartiremos su información con terceros sin su permiso. <br><br>
<strong>Información financiera:</strong> Para garantizar la emisión fluida de préstamos, necesitamos recopilar la información de su tarjeta bancaria, incluyendo el nombre del banco y el número de tarjeta. Esta información solo se utiliza para la emisión de préstamos después de la aprobación, y será estrictamente confidencial y encriptada para garantizar la seguridad de su información financiera personal, y no se utilizará para otros fines ni se compartirá con terceros no autorizados.<br><br>
<strong>Información de la lista de contactos: </strong>Para evaluar con precisión su calificación crediticia y su riesgo de fraude, Oro Crédito requiere que los usuarios completen la información de contacto de emergencia (incluida la relación de contacto, el nombre, el número de teléfono) al solicitar un préstamo. Usamos DES y AES para procesar el préstamo. Cifre los datos recopilados y cárguelos en https://orodqp.orocr5ed3itopres.com/tidop/ después del cifrado. No compartimos datos sobre otros contactos con terceros.<br><br>
<strong>Permiso de cámara:</strong>Solicitamos acceso a la cámara para tomar fotografías de identificaciones y realizar reconocimiento facial. Garantizamos que las fotografías tomadas únicamente se utilizan para brindar servicios y evaluar la solvencia de los usuarios y prevenir fraudes. No para ningún otro propósito y no compartiremos información del usuario con terceros sin el permiso del usuario.<br><br>
<strong>Información GAID:</strong> Cuando utiliza Oro Crédito, recopilaremos su ID de publicidad de Google (GAID) para optimizar y analizar la publicidad, proporcionar visualización personalizada según su comportamiento e intereses, y mejorar la experiencia del usuario. Puede optar por "optar por no recibir publicidad personalizada" en cualquier momento.<br><br>

<!-- <strong>Información de SMS:</strong> Cuando un usuario utiliza Oro Crédito, con el consentimiento del usuario, accederemos a la información de SMS almacenada en el dispositivo, incluido el contenido del mensaje de texto, la hora de envío, la información del operador y el estado del SMS, para realizar una evaluación crediticia, prevenir fraudes financieros y proporcionar servicios de personalización. Los datos leídos se cifrarán y se cargarán en nuestro servidor seguro: https://orodqp.orocr5ed3itopres.com/tidop/ y se almacenarán de forma cifrada. Oro Crédito promete que tus datos estarán estrictamente protegidos y nunca serán compartidos con terceros.<br><br>
<strong>Registros de llamadas:</strong> Cuando un usuario obtiene un código de verificación de voz, Oro Crédito solicitará permiso para obtener los registros de llamadas del usuario. El propósito es confirmar si su teléfono móvil ha recibido una llamada del número de envío de voz a través de nuestra aplicación, para garantizar la seguridad de la cuenta y evitar que la roben. Cifraremos la información recopilada y la subiremos a https://orodqp.orocr5ed3itopres.com/tidop/ para proteger su privacidad y nunca la compartiremos con terceros.<br><br>
<strong>Geolocalización:</strong> Cuando un usuario utiliza Oro Crédito, con el consentimiento del usuario, obtendremos información aproximada de su ubicación en tiempo real. Esta información nos ayuda a tomar mejores decisiones sobre los riesgos de la información y a proporcionar algunos productos y servicios regionales. Puedes desactivar la información de ubicación en tu dispositivo móvil en cualquier momento, pero esto puede afectar tu uso normal de Oro Crédito. Tenga la seguridad de que nunca compartiremos esta información con terceros.<br><br>
<strong>Información sobre las aplicaciones instaladas: </strong>Cuando los usuarios utilicen Oro Crédito, recopilaremos información sobre las aplicaciones instaladas en su dispositivo, incluido el nombre de la aplicación, el nombre del paquete, el tiempo de instalación y el tiempo de actualización, con el fin de evaluar su valor de riesgo actual y está encriptado y subido a nuestro servidor https://orodqp.orocr5ed3itopres.com/tidop/, que puede identificar aplicaciones potencialmente ilegales con una alta probabilidad y prevenir el fraude al cliente. Tenga la seguridad de que puede identificar aplicaciones potencialmente ilegales con una alta probabilidad, evitar el fraude del usuario y se eliminará después de la verificación.<br><br>
<strong>Información del dispositivo:</strong> Cuando un usuario se registra en Oro Crédito y solicita un préstamo, necesitamos recopilar SSAID, dirección MAC, IMEI, modelo de hardware, versión del sistema operativo, etc. Esta información sólo se utiliza para garantizar la seguridad y legalidad del uso de CreditoClaro por parte de los usuarios y para identificar diversos comportamientos y riesgos crediticios. No compartiremos ni compartiremos su información con terceros sin su permiso.<br><br>
<strong>Información de imágenes: </strong>Para optimizar la experiencia del usuario y mejorar la eficiencia del servicio al cliente, podemos solicitar permisos del álbum cuando los usuarios dan comentarios o se comunican con el servicio al cliente para facilitar que los usuarios carguen imágenes. Esta imagen solo se utiliza para el análisis y procesamiento de problemas para garantizar la seguridad de la información. La información recopilada se cifrará y se cargará en https://orodqp.orocr5ed3itopres.com/tidop/. Nunca será compartido con ningún tercero sin el consentimiento del usuario. -->

<div class="t">Cómo utilizamos la información que recopilamos:</div> 
Utilizaremos su información personal legalmente de acuerdo con las leyes y regulaciones. Su información personal se utiliza principalmente para<br>
(1) Se utiliza para verificar la identidad del usuario para análisis de riesgos, evaluación crediticia, prevención y detección de actividades fraudulentas.<br>
(2) Analizar la información proporcionada por los usuarios para determinar niveles de riesgo, capacidades de pago, condiciones de operación y cualesquiera otras evaluaciones requeridas por las leyes y reglamentos con el fin de brindar servicios de conformidad con las normas legales vigentes.<br>
(3) Usar su información para mejorar los servicios de la aplicación, mejorar su experiencia y notificarle sobre actualizaciones del servicio.<br>
(4) Personalizar su experiencia y brindarle los productos crediticios que sean de mayor interés para usted.<br>
(5) Proteger nuestros derechos, por ejemplo enviando recordatorios oportunos y realizando actividades de cobranza para recuperar los montos adeudados, incluida la gestión de listas de morosos y el mantenimiento de información que pueda usarse como prueba en juicios e inspecciones.<br>
(6) Contactarle para informarle sobre cualquier cambio en nuestras condiciones, productos o servicios.<br>

<div class="t">Cómo almacenamos y protegemos su información personal</div> 
(1) Toda la información y los datos que nos proporciona se almacenan de forma segura en nuestro centro de seguridad de datos (https://orodqp.orocr5ed3itopres.com/tidop/). Todos los tipos de transacciones de pago se cifrarán utilizando la tecnología Secure Sockets Layer (SSL).<br>
(2) También utilizamos estrictos controles de acceso técnicos y administrativos para limitar el acceso de los empleados y ciertos contratistas a información personal no pública, y registramos y monitoreamos el acceso para evitar divulgaciones relacionadas.
<br>
(3) A menos que las leyes y reglamentos dispongan lo contrario, cuando deje de utilizar nuestra aplicación, directamente dejaremos de recopilar y utilizar su información. Si cesamos nuestras actividades, se lo comunicaremos mediante una notificación. Eliminaremos automáticamente cualquier información personal que deje en la Aplicación y el Sitio web.
<br>
(4) La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Aunque hacemos todo lo posible por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.<br>

<div class="t">Derechos del usuario</div> 
Valoramos y hacemos todo lo posible para proporcionarle el derecho de acceso, modificación, eliminación y cancelación de su cuenta.
<br>
(1) Acceso a su información personal. Sujeto a las excepciones establecidas en las leyes y reglamentos, usted tiene derecho a saber qué información personal tenemos sobre usted, los fines para los que la utilizamos y las condiciones en las que permitimos el acceso a ella.<br>
(2) Rectificación de su información personal. Tiene derecho a que se corrija su información personal si está desfasada, es inexacta o incompleta; tiene derecho a solicitar que se elimine su información personal de nuestros registros si creemos que se está utilizando de forma que vulnera los principios, deberes y obligaciones establecidos en la normativa; y tiene derecho a oponerse al tratamiento de su información personal para un fin específico.<br>
(3) Supresión de sus datos personales. Puede solicitar que eliminemos sus datos personales en los siguientes casos:
-Damos por terminados nuestros negocios y servicios;
-Haya expirado el periodo de conservación de sus datos personales o usted haya cancelado su cuenta;
<br>
-Hemos procesado sus datos personales sin su consentimiento;<br>
-Hemos infringido las leyes y normativas o esta Política de Privacidad. Puede ponerse en contacto con nuestro servicio de atención al cliente dentro de la aplicación. Una vez recibida su solicitud, completaremos la revisión y el procesamiento en un plazo de 60 días y eliminaremos su información de nuestros Servicios. Comprenda que nuestro plazo de procesamiento es limitado, ya que el almacenamiento y el procesamiento de la información requieren múltiples operaciones y aprobaciones departamentales.<br>
(4)Cancelación de la cuenta. Puede solicitar la cancelación de su cuenta en cualquier momento poniéndose en contacto con nuestro departamento de atención al cliente dentro de la Aplicación, o puede hacerlo usted mismo dentro de la Aplicación. Sin embargo, no podrá cancelar su cuenta si tiene o está trabajando en un asunto pendiente. Una vez que cancele su cuenta, dejaremos de prestarle servicios; le rogamos que actúe con cautela.<br>
(5)Cambios a esta Política:
Revisaremos esta política de vez en cuando, debido a que la participación de mercado de los usuarios es muy amplia y diversa, si hay un cambio, optaremos por anunciarlo a través del sitio web en lugar de contactarlo personalmente, y el cambio se publicará. después de la emisión y obtener su consentimiento efectivo. Después de cambiar esta política, asumiremos que comprende y acepta el acuerdo. Vuelva a consultar con frecuencia para conocer las actualizaciones o los cambios en nuestra Política de privacidad.<br>

<div class="t">Protección de Datos Personales de Menores</div> 
CreditoClaro se toma muy en serio la protección de la información personal de los niños. Nuestro sitio web y nuestros servicios están destinados a ser utilizados únicamente por adultos. Durante la sesión de verificación del nombre real, utilizaremos el código de identificación recogido para determinar la edad del usuario. Si es menor de dieciocho años, no utilice los productos que ofrecemos.
<br> <br>
Si no tiene el consentimiento de su tutor o no da su consentimiento para que su tutor utilice nuestros servicios y nos facilite información, deje de utilizar inmediatamente nuestros servicios y póngase en contacto con nosotros de inmediato. Si descubrimos que hemos recopilado información personal de menores de 18 años sin el consentimiento previo de sus tutores, dejaremos inmediatamente de recopilar y utilizar la información pertinente y eliminaremos los datos correspondientes a su debido tiempo.

<div class="t">Actualizar esta política</div> 
Esta política de privacidad se actualizará a medida que se desarrollen nuestras actividades para brindarle mejores servicios. Cuando las actualizaciones de contenido relevantes entren en vigor, publicaremos la versión actualizada en nuestro sitio web, aplicación u otras plataformas, y se lo recordaremos a través de los medios adecuados, como anuncios, mensajes en el sitio o ventanas emergentes. Consulte la política de privacidad más reciente lo antes posible.
<br>
Si no está de acuerdo con los cambios a esta Política de Privacidad, deje de utilizar los productos o servicios de Oro Crédito. Al continuar utilizando nuestros productos o servicios, usted acepta estar sujeto a la Política de Privacidad actualizada.

<div class="t">Contáctenos:</div> 
Si encuentra alguna pregunta (incluidas consultas, quejas y sugerencias) relacionada con la protección de la privacidad o esta política durante el uso de nuestros servicios, puede contactarnos por los siguientes medios: feufuw454@gmail.com.

        <p v-show="showmore" @click="showmore = !showmore" class="show"></p>
      </div>
    </div>
    <div id="foot">
    
      <div class="contact">
        <p>
          DIRECCIÓN:
          <br />Av Eloy Cavazos 7800, Jardines de Andalucía, 67150 Guadalupe, N.L.
        </p>
        <p>
          Correo:
          <br />feufuw454@gmail.com
        </p>
      </div>
      <p class="end">
        © 2024 Oro Crédito. Todos los derechos reservados.</p>
    </div>
  </div>
</template>
<style lang="less">


  
*{
  font-size: 1vw;
}
.title1 {
  color: #fff;
  font-family: Baloo2-ExtraBold, Baloo2;
  font-weight: 800;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  font-size: 35px;
  margin-left: 150px;
}
a {
  text-decoration: none;
}
strong {
  font-weight: 600;
}

header {
  width: 100%;
  height: 4vw;
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000000000000;
  line-height: 4vw;

  .logo {
    margin-left: 10vw;
  

    .logoimg {
      margin-top: 1vw;
      width: 2vw;
      height: 2vw;
      background: url(../assets/img/logo.png) no-repeat;
      background-size: 100%;
      float: left;
    }

    .logoname {
      font-family: Helvetica-Bold;
      font-size: 1.2vw;
      color: #333333;
      float: left;
      margin-left: 1vw;
    }
  }

  nav {
   
    display: flex;

    margin-right: 10vw;

    a {
      margin: 0 1vw;
      font-family: Helvetica-Bold;
      font-size: 1vw;
      color: #333333;
      text-align: left;
      text-align: center;
      text-transform: uppercase;
      /* border-bottom: 2px solid #fff; */
    }

    a:hover {
      color: #e25f31;
      /* border-bottom: 2px solid rgba(61,76,233); */
      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/img/sobre.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 40vw;
  overflow: hidden;
  margin-top: 4vw;
  display: flex;
  justify-content: space-between;
  padding: 0vw 10vw;
  box-sizing: border-box;
  align-items: center;

  .mytitle {
    width: 30vw;
    font-size: 3vw;
    font-weight: 600;
    .min {
      font-weight: 400;
      line-height: 1.6;
      margin-top: 2vw;
      font-size: 1vw;
    }
  }
  .homeimg {
    width: 20vw;
    img {
      width: 100%;
    }
  }

  .googlelogo {
    display: block;
    background: url(../assets/images/Googleplay.png) no-repeat;
    background-size: 100%;
    width: 376px;
    height: 112px;
    margin-top: 80px;
    margin-left: 150px;
  }
}

.product {
  .title {
    padding: 5vw 0 1.2vw;
    font-family: Helvetica-Bold;
    font-size: 2vw;
    color: #333333;
    text-align: center;
  }
  .productlist {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .productitem {
      float: left;
      text-align: center;
      width: 20vw;
      padding: 2vw;
      background-color: #fff;
      box-shadow: 0px 5px 30px 0 rgba(220, 220, 220, 0.5);
      border-radius: 1.5vw;
      margin-right: 1vw;
      height: 15vw;
      img {
        width: 5vw;
        height: 5vw;
        margin-top: 2vw;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 1vw;
        color: #333333;
        text-align: center;
       
        margin-top: 1vw;
        padding-bottom: 2vw;
      }
    }
  }
}

.company {
  padding: 5vw 10vw 0;
  margin-top: 10vw;
  box-sizing: border-box;
  background-image: url(../assets/img/card22.png);
  background-size: 100% 100%;
  height: 35vw;
  width: 100vw;
  .title {
    padding: 2vw 0 1.2vw;
    font-family: Helvetica-Bold;
    font-size: 2vw;
    color: #333333;
    text-align: center;
  }
  .companylist {
    .companyitem {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .left {
        img {
          width: 20vw;
        }
      }

      .right {
        .msg {
          font-family: Helvetica;
          font-size: 1.2vw;
          color: #333333;
          text-align: left;
          .msgitem {
            margin-bottom: 1vw;
            img {
              width: 2vw;
              vertical-align: middle;
              margin-right: 1vw;
            }
          }
        }
      }
    }
  }
}

.features {
  padding: 3vw 10vw;
  margin-top: 8vw;
  height: 30vw;
  background-image: url(../assets/img/sobre.png);
  background-size: 100% 100%;
  .title{
   
    font-family: Helvetica-Bold;
    font-size: 2vw;
    color: #333333;
    text-align: center;
  }
  .companyitem {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .left {
      img {
        width: 20vw;
      }
    }

    .right {
      .msg {
       width: 35vw;
        font-family: Helvetica;
        font-size: 1.1vw;
        color: #666666;
        text-align: left;
        line-height: 1.4;
      }
    }
  }
}

.about {
  height: 500px;
  padding: 80px 180px;
  background: linear-gradient(315deg, #f54451, #9c55fd);
  text-align: center;

  .title {
    font-family: Helvetica-Bold;
    font-size: 44px;
    color: #ffffff;
    padding: 0;
    padding-bottom: 60px;
  }

  .aboutmsg {
    font-family: Helvetica;
    font-size: 24px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
  }
}

.privacy {

  position: relative;
  width: 80vw;
  margin-left: 10vw;
margin-top: 5vw;
margin-bottom: 5vw;
font-size: 1vw;
  .title {
    font-weight: 600;

font-size: 1.4vw;text-align: center;
margin-bottom: 2vw;
  }

  .privacymsg {
    font-family: Helvetica;
    height: 50vw;
    overflow: hidden;
    text-align: left;
    

    p {
      font-weight: 600;
      text-align: center;
    }

    .show {
      background: url(../assets/img/more.png) no-repeat;
      background-size: 100%;
      width: 2vw;
      height: 2vw;
      position: absolute;
      top: 54vw;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .showmore {
    height: auto;
    overflow: auto;
  }
}

#foot {
  
  background: #2f2f2f;
  color: #fff;
  padding: 3vw 0;
  .title {
    color: #fff;
    padding: 2vw 0 0 !important;
  }

  .contact {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1vw;
    color: #ffffff;
    text-align: left;
   
    p {
      font-family: Helvetica;
      font-size: 1vw;
      color: #ffffff;
      text-align: left;
      line-height: 1.4;
    }
  }

  .end {
    margin-top: 2vw;
    width: 100%;
    text-align: center;
    font-family: Helvetica;
    font-size: 1vw;
    color: #ffffff;
   
  }
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      showmore: true,
    }
  },
  methods: {
    opengooglepaly(){
      window.open('https://play.google.com/store/apps/details?id=mx.orocredito.prest')
    },
    jumcuenta() {
      this.$router.replace("/Account")
    },
  },
}
</script>
